import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import '../App.css';
import fs from "fs";
import Web3 from 'web3';
import MasksOfEther from '../build/contracts/MasksOfEther.json'
import {Navbar, Nav, NavDropdown, Container} from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Spinner from 'react-bootstrap/Spinner'

import 'bootstrap/dist/css/bootstrap.min.css';

import { Link, Element } from 'react-scroll'


import logo from '../images/mask_circular.png'; 

import maskOne from '../images/masks/Mask #15.png'
import maskTwo from '../images/masks/Mask #10.png'
import maskThree from '../images/masks/Mask #8.png'
import maskFour from '../images/masks/Mask #29.png'

import maskGif from '../images/masks/masks_animation.gif'

import maskSub_1 from '../images/masks/Mask #22.png'
import maskSub_2 from '../images/masks/Mask #23.png'
import maskSub_3 from '../images/masks/Mask #24.png'
import maskSub_4 from '../images/masks/Mask #17.png'
import maskSub_5 from '../images/masks/Mask #30.png'
import maskSub_6 from '../images/masks/Mask #3.png'
import maskSub_7 from '../images/masks/Mask #19.png'
import maskSub_8 from '../images/masks/Mask #12.png'
import maskSub_9 from '../images/masks/Mask #6.png'
import maskSub_10 from '../images/masks/Mask #16.png'

import charitySavannahImg from '../images/crypto_savannah.png'
import charitySurvival from '../images/survival.png'
import charityFund from '../images/eth.png'


import ornament_1 from '../images/attributes/bottom_ornament_8.png'
import ornament_2 from '../images/attributes/pattern_15.png' 
import ornament_3 from '../images/attributes/ornament_2.png'
import ornament_4 from '../images/attributes/mouth_15.png'
import ornament_5 from '../images/attributes/ornament_4.png'
import ornament_6 from '../images/attributes/ornament_8.png'
import ornament_7 from '../images/attributes/pattern_10_brown_cream.png'
import ornament_8 from '../images/attributes/eyes_12.png'
import ornament_9 from '../images/attributes/bottom_ornament_3.png'
import ornament_10 from '../images/attributes/bottom_ornament_5.png'
import ornament_11 from '../images/attributes/eyes_9.png'
import ornament_12 from '../images/attributes/mouth_20.png'
import ornament_13 from '../images/attributes/ornament_6.png'
import ornament_14 from '../images/attributes/pattern_4_orange_blue.png'
import ornament_15 from '../images/attributes/pattern_14_red_orange.png'
import ornament_16 from '../images/attributes/nose_12.png'

import mintImage from '../images/mint_image.png'

import discord from '../images/discord.png'
import twitter from '../images/twitter.png'
import opensea from '../images/opensea.png'

import maskHarry from '../images/masks/Mask #27.png'

import maskFelicity from '../images/masks/Mask #11.png'


import provenance_record from '../provenance_record.txt'; 
import concatenated_hash from '../concatenated_hash.txt'; 

import nftCalendar from "../images/nftcalendar-logo.png"



const finalHash = "BKre0/7OppsMJ44aAMxqbtDI/jkgISDAse33UJC2IE8="
//const concatenatedHash = provenance.concatenatedHash
//const result = provenance.result
//var provenanceComponent




class App extends Component {

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
}

resize() {
  let currentHideNav = (window.innerWidth <= 760);
  if (currentHideNav !== this.state.isMobile) {
      this.setState({isMobile: currentHideNav});
  }
}

componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
}


  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }

  }

  async loadBlockchainData() {
    const web3 = window.web3
    //load accounts
    const accounts = await web3.eth.getAccounts()
    this.setState({account: accounts[0] })

    const networkId = await web3.eth.net.getId()
    const networkData = MasksOfEther.networks[networkId]
    if(networkData) {
      const masksOfEther = new web3.eth.Contract(MasksOfEther.abi, networkData.address)
      this.setState({masksOfEther})
      this.setState({loading: false}) 
      this.setState({connected: true})

      console.log(masksOfEther.options.address)
      console.log(await masksOfEther.methods.name().call())


      const totalMasksClaimed = await this.state.masksOfEther.methods.totalSupply().call()
      this.setState({totalMasksClaimed})
      console.log(totalMasksClaimed)

      const ethBalance = await web3.eth.getBalance(this.state.account)
      this.setState({ethBalance})

      this.setState({saleIsActive: await this.state.masksOfEther.methods.saleStarted().call()})     
      


    } else {
      window.alert('Masks Of Ether has not been deployed to detected network')
    }


  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return <span className='countdownTitle'>Minting is Live!</span>

    } else {
      // Render a countdown
      return (
        <Container>
          <Row>
            <Col>
              <Row>
                <span className='countdownTitle'>{this.twoDigits(days)}</span>
              </Row>
              <Row>
                <span className='countSubTitle'>Days</span>
              </Row>
            </Col>
            <Col>
              <Row>
                <span className='countdownTitle'>{this.twoDigits(hours)}</span>
              </Row>
              <Row>
                <span className='countSubTitle'>Hours</span>
              </Row>
            </Col>
            <Col>
              <Row>
                <span className='countdownTitle'>{this.twoDigits(minutes)}</span>
              </Row>
              <Row>
                <span className='countSubTitle'>Minutes</span>
              </Row>
            </Col>
            <Col>
              <Row>
                <span className='countdownTitle'>{this.twoDigits(seconds)}</span>
              </Row>
              <Row>
                <span className='countSubTitle'>Seconds</span>
              </Row>
            </Col>
          </Row>
        </Container>
      );
    }
  };

  twoDigits = (n) => {
    return (n < 10 ? "0" : "") + n;
  }


  connectUser = async () => {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  purchaseMask = async () => {
    const tokenAmount = this.state.mintCount
    const totalCost = tokenAmount * 0.08
    this.state.masksOfEther.methods.createMask(tokenAmount).send({from: this.state.account, value: window.web3.utils.toWei(totalCost.toString(), 'Ether'), type: "0x2"})
      .on('transactionHash', (hash) => {
                       
      })
      .on("confirmation", (confirm) => {
        this.state.masksOfEther.methods.walletOfUser(this.state.account).call().then((userTokens) => {
          this.setState({userTokens: userTokens })
          this.setState({minting: false})
          this.setState({mintSuccess: true })
        })   

      })
      .on('error', (error) => {
        this.setState({minting: false})
        window.alert('Error with minting, from transaction')
        
      })
      .on('sent', (sent) => {
        this.setState({minting: true})
        
      })

  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      masksOfEther: null,
      loading: true,
      minting: false,
      totalMasksClaimed: 0,
      ethBalance: 0,
      mintCount: 1,
      saleIsActive: false,
      userTokens: [],
      provenanceLoaded: false,
      

    }

    console.log("Constructor called")

  }


  render() {
    return (
      <div className="App">
           <Navbar bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="#home">
            <img
                alt=""
                src={logo}
                width="35"
                height="35"
                className="d-inline-block align-top "/>{' '} <span className="nav-main">Masks of Ether</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link >
                  <Link to="mission" className='nav-scrolllink'>Mission</Link> 
                </Nav.Link>   
                
                <Nav.Link>
                  <Link to="traits" className='nav-scrolllink'>Traits</Link>
                </Nav.Link> 

                <Nav.Link> 
                  <Link to="roadmap" className='nav-scrolllink'>Roadmap</Link>
                </Nav.Link>

                <Nav.Link>   
                  <Link to="mint" className='nav-scrolllink'>Mint</Link>
                </Nav.Link>  

                <Nav.Link>        
                  <Link to="faq" className='nav-scrolllink'>FAQ</Link>
                </Nav.Link>  

                <Nav.Link> 
                  <Link to="provenance" className='nav-scrolllink'>Provenance</Link>
                </Nav.Link>     
              </Nav>
            </Navbar.Collapse>
          </Container>
      </Navbar>

         
        
        <Container>
          <Row>
            <Col className='mainCol'>
              <h1 className='welcome'>Welcome to Masks of Ether</h1>
              <p className='welcomeParagraph'>A collection of 10000 generative masks inspired by African tribal art.</p>
              <p className='welcomeParagraph'>Masks of Ether aims to share rich African culture on the Blockchain and promote diversity and inclusivity.</p>
              <p className='welcomeParagraph' ><span style={{color: "#FFE400"}}>Masks of Ether has launched and can be minted!</span></p>
            </Col>
            <Col>
              <Row>
                <Col>
                <Image className="maskImage" src={maskOne} />
                </Col>
                <Col>
                <Image className="maskImage" src={maskTwo} />
                </Col>
              </Row>
              <Row className="row2">
                <Col>
                <Image className="maskImage" src={maskThree} />
                </Col>
                <Col>
                <Image className="maskImage" src={maskFour} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {/* <Container>
          <Row>
            <Col>
            <h2 className='mainCountdownTitle'>Launch Countdown</h2>
              <Countdown className='countdownTitle' date={Date.parse('05 Oct 2021 17:00:00 GMT')} renderer={this.renderer}/>
            </Col>
          </Row>
        </Container> */}

        <Container>
        <Row className="align-items-center">
            <Col sm={8} className='mainCol'>
              <h2 className='mainTitle'>The Masks</h2>
              <p className='mainParagraph'>• The collection is built with the <span style={{color: "#FFE400"}}>ERC-721</span> standard and files are hosted on <span style={{color: "#FFE400"}}>IPFS.</span></p>
              <p className='mainParagraph'>• There are exactly <span style={{color: "#14A76C"}}>10000 masks</span> each one is unique.</p>
              <p className='mainParagraph'>• The launch will be fair with <span style={{color: "#FF652F"}}>fair token distribution.</span> There are NO bonding curves.</p>
              <p className='mainParagraph'>• Each Mask costs <span style={{color: "#FFE400"}}>0.08 ETH.</span> There are no price tiers. Everyone pays the same price.</p>
              <p className='mainParagraph'>• You receive <span style={{color: "#14A76C"}}>full ownership</span> and <span style={{color: "#14A76C"}}>commercial rights</span> over your NFT.</p>
           
            </Col>
            <Col sm={4}>
              <Image className="maskImage" src={maskGif} />
            </Col>
        </Row>
        </Container>

        
        
        <Container>
          <Row>
          <Col>                  
            <Image className='subImage' src={maskSub_1}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_2}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_3}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_4}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_5}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_6}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_7}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_8}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_9}/>
          </Col>
          <Col>                  
            <Image className='subImage' src={maskSub_10}/>
          </Col>
          </Row>
        </Container>

        <Element id='mission' name='mission'>
        <Container>
        <Row>
            <Col className='mainCol'>
              <h2 className='mainTitle'>The Mission</h2>
              <p className='mainParagraph'>Our work is inspired by the beautiful masks created by African tribal communities. We combine their unique aesthetic with a pixel art style pioneered
by one of the original NFT projects: CryptoPunks.</p>
              <p className='mainParagraph'>We are grateful for the inspiration that the Tribal communities have provided us with, hence we would love to give back to those communities. Additionally, we strongly believe
in the value of diversity in the Blockchain and NFT space, as it will
create a richer and more inclusive environment. Hence, we will donate to CryptoSavannah and Survival International as they represent these values.</p>
              <p className='mainParagraph'>We will also launch a community fund, which will support the Masks of Ether and NFT communities. More details on the community fund can be found on our <a style={{textDecoration: "none"}} target={"_blank"} rel="noreferrer" href="https://discord.gg/Td2wbJ4Meu">Discord</a> and <a style={{textDecoration: "none"}} target={"_blank"} rel="noreferrer" href="https://twitter.com/MasksOfEther">Twitter</a>.</p>
              <h3 className='subTitle'>Supported Causes</h3>
              <Row> 
                <Col style={{textAlign: "center"}}>     
                <a href="https://cryptosavannah.com/index.php" target={"_blank"} rel="noreferrer" className="charityLink">  
                  <Image className="charityImage" src={charitySavannahImg} roundedCircle/>
                  <p className='charityParagraph' style={{marginTop:"8px"}}>CryptoSavannah</p>
                </a>   
                </Col>
                <Col style={{textAlign: "center"}}>    
                <a href="https://survivalinternational.org/" target={"_blank"} rel="noreferrer" className="charityLink">  
                  <Image className="charityImage" src={charitySurvival} roundedCircle/>
                  <p className='charityParagraph' style={{marginTop:"8px"}}>Survival International</p>
                </a>
                </Col>
                <Col style={{textAlign: "center"}}>                  
                  <Image className="charityImage" src={charityFund} roundedCircle/>
                  <p className='charityParagraph' style={{marginTop:"8px"}}>Community Fund</p>
                </Col>
              </Row>
            </Col>
        </Row>
        </Container>
        </Element>


        <Element id='traits' name='traits'>
        <Container>
          <Row>
            <Col className='mainCol'>
              <h2 className='mainTitle'>Traits and Attributes</h2>
              <p className='mainParagraph'>There are a wide variety of traits, which have over 140 unique attributes. Look out for rare mask ornaments and the special Harmony mask pattern.</p>
              <Row>
                <Col> <Image src={ornament_1} className='traitImage' roundedCircle/> </Col>
                <Col> <Image src={ornament_2} className='traitImage' roundedCircle/> </Col>
                <Col> <Image src={ornament_3} className='traitImage' roundedCircle/> </Col>
                <Col> <Image src={ornament_4} className='traitImage hideImg' roundedCircle/> </Col>
              </Row>
              <Row>
                <Col> <Image src={ornament_5} className='traitImage hideImg' roundedCircle/> </Col>
                <Col> <Image src={ornament_6} className='traitImage hideImg' roundedCircle/> </Col>
                <Col> <Image src={ornament_15} className='traitImage hideImg' roundedCircle/> </Col>
                <Col> <Image src={ornament_8} className='traitImage hideImg' roundedCircle/> </Col>
              </Row>
            </Col>
              
            <Col>
              <Container className ="infoBox" style={{minWidth:"300px", backgroundColor: "#4E4E4E", marginTop: "20px", padding: "20px",  borderRadius: "25px"}}>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Masks</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>10000</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Backgrounds</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>10</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Background Colors</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>5</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Bases</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>21</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Patterns</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>15</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Pattern Colors</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>14</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Eyes</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>23</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Noses</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>17</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Mouths</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>22</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Top Ornaments</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>10</p></Col>
              </Row>
              <Row>
                <Col className="leftTraitsCol"><p className='boxParagraphTitle'>Bottom Ornaments</p></Col>
                <Col className="rightTraitsCol"><p className='boxParagraph'>9</p></Col>
              </Row>
              </Container>
            </Col>

          </Row>
        </Container>
        </Element>

       

        <Element id='roadmap' name='roadmap'>
        <Container>
          <Row>
              <Col className='mainCol'>
                <h2 className='mainTitle'>The Road Map</h2>
                <p className='mainParagraph'>We want to deliver as much value as possible to our community. Have a look at the things we aim to do as a certain % of Masks have been minted:</p>
                <p className='mainParagraph'><span style={{color: "#14A76C"}}>10%</span>  — We pay back our early investors</p>
                <p className='mainParagraph'><span style={{color: "#14A76C"}}>20%</span>  — Airdrop 10 Masks of Ether to random owners</p>
                <p className='mainParagraph'><span style={{color: "#14A76C"}}>40%</span>  — We donate $50,000 to CryptoSavannah</p>
                <p className='mainParagraph'><span style={{color: "#14A76C"}}>60%</span>  — We donate $50,000 to Survival International</p>
                <p className='mainParagraph'><span style={{color: "#14A76C"}}>80%</span>  — The community fund launches with at least 20 ETH. The fund will be used to support the Masks of Ether community and other NFT communities</p>
                <p className='mainParagraph'><span style={{color: "#14A76C"}}>100%</span> — We select 10 random owners and send them hoodies with their NFT’s on it</p>
                </Col>
          </Row>
        </Container>
        </Element>
 
        <Element id='mint' name='mint'>
        <Container>
          <Row>
              <Col className='mainCol'>
                <h2 className='mainTitle'>Mint Masks of Ether</h2>
                <Container style={{backgroundColor: "#4E4E4E", marginTop: "20px", padding: "20px",  borderRadius: "25px"}}>
                  <Row>
                    <Col className="leftTraitsCol"><p className='mainParagraphTitle'>Mint</p></Col>
                    <Col className="rightTraitsCol"><p className='mainParagraph'>Price: 0.08 ETH</p></Col>
                  </Row>
                  <hr style={{color: "#ffffff"}}></hr>
                  <Row>
                    <Col className="leftTraitsCol"><p className='mainParagraphTitle'>ETH Balance</p></Col>
                    <Col className="rightTraitsCol"><p className='mainParagraph' id='ethBalance'>{this.state.connected ? parseFloat(window.web3.utils.fromWei(this.state.ethBalance.toString(), 'Ether')).toFixed(4).toString() + " ETH" : "-" }</p></Col>
                  </Row>
                  <Row>
                    <Col className="leftTraitsCol"><p className='mainParagraphTitle'>Amount</p></Col>
                    <Col className="rightTraitsCol">
                      <Row>
                        <Col style={{textAlign: 'start'}}>     
                          <Button className="stepperBtn"  size="sm" variant="warning" onClick={(event) => {
                            if(this.state.mintCount > 1) {
                              this.setState({mintCount: this.state.mintCount - 1 })
                            }
                          }}>
                          <span style={{fontWeight: 700, fontSize:"18px", color: '#4E4E4E'}}>-</span>
                          </Button>{' '}
                        </Col>
                        <Col style={{textAlign: 'center'}}> <p className='mainParagraph' id='tokenAmount'>{this.state.mintCount}</p> </Col>
                        <Col style={{textAlign: 'end'}}>
                          <Button className="stepperBtn"  size="sm" variant="warning" onClick={(event) => {
                            if(this.state.mintCount < 30) {
                              this.setState({mintCount: this.state.mintCount + 1 })
                            }
                          }}>
                          <span style={{fontWeight: 700, color: '#4E4E4E'}}>+</span>
                          </Button>{' '}
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="leftTraitsCol"><p className='mainParagraphTitle'>Total price</p></Col>
                    <Col className="rightTraitsCol"><p className='mainParagraph' id="totalPrice">{parseFloat(this.state.mintCount * 0.08).toFixed(2)} ETH</p></Col>
                  </Row>
                  <Row>
                    <Col className="center">
                      {this.state.connected
                      ? <Button style={{backgroundColor: "#C23200", borderColor:"#C23200", borderRadius: "50px", paddingLeft: "15px", paddingRight: "15px"}} variant="primary" size="lg" disabled={!this.state.saleIsActive || this.state.minting} onClick={ async () => {

                          //only clickable when sale started
                          this.setState({minting: false })
                          this.setState({mintSuccess: false })
                          this.purchaseMask()
                      }}>
                          <span>Mint</span>
                        </Button> 
                      : <Button style={{backgroundColor: "#C23200", borderColor:"#C23200", borderRadius: "50px", paddingLeft: "15px", paddingRight: "15px"}} variant="primary" size="lg"  onClick={ async () => {
                            await this.connectUser()
                        }}>
                          <span>Connect to MetaMask</span>
                        </Button>}                      
                    </Col>
                  </Row>
                </Container>

                  {/* <div style={{marginTop:"10px"}}>
                    <Row>
                        <Col className="leftTraitsCol"><p className='mainParagraph' style={{fontSize: "18px"}}>Progress</p></Col>
                        <Col className="rightTraitsCol"><p className='mainParagraph' style={{fontSize: "18px"}}>{this.state.connected ? `${this.state.totalMasksClaimed} / 10000 Minted` : "Visible when connected"} </p></Col>
                    </Row>
                    <Row>
                        <Col className="leftTraitsCol">  
                          <ProgressBar style={{height: "8px"}} variant="warning" now={this.state.connected ? this.state.totalMasksClaimed / 100 : 0} />
                        </Col>
                    </Row>
                  </div> */}
              </Col>

              <Col style={{minWidth:"300px"}}>
                {
                  this.state.minting 
                  ? <Col style={{textAlign: "center"}}><h3 className='subTitle' style={{  marginTop: "30px"}}>Minting...</h3></Col> 
                  : this.state.mintSuccess ?<h3 className='subTitle' style={{  marginTop: "30px"}}>Your Masks</h3> : null
                }
                {
                  this.state.minting 
                  ?  <Col style={{textAlign: "center"}}> <Spinner animation="border" variant="warning" /> </Col> 
                  : this.state.mintSuccess ?  <Container style={{height: "400px", overflowY: "scroll"}}><Row> {this.state.userTokens.map((token) => { return(<p className='listParagraph'><a className="listParagraph" target={"_blank"} rel="noreferrer" href={`https://opensea.io/assets/${this.state.masksOfEther.options.address}/${token}`}>{`Mask #${token}`}</a></p>)}) }</Row> </Container> : <Image className="mintImage" src={mintImage}/> 
                  
                }
              </Col>
          </Row>
        </Container>
        </Element>

        <Container>
        <Row>
            <Col className='mainCol'>
            <h3 className='subTitle'>Join the Tribe</h3>
              <Row> 
                <Col style={{textAlign: "center"}}>     
                <a href="https://discord.gg/Td2wbJ4Meu" target={"_blank"} rel="noreferrer" className="socialMediaLink">  
                  <Image className="socialMediaImage" src={discord} roundedCircle/>
                  <p className='socialMediaParagraph' style={{marginTop:"10px"}}>Discord — Community</p>
                </a>   
                </Col>
                <Col style={{textAlign: "center"}}>    
                <a href="https://twitter.com/MasksOfEther" target={"_blank"} style={{resize: "cover"}} rel="noreferrer" className="socialMediaLink">  
                  <Image className="socialMediaImage" src={twitter} roundedCircle/>
                  <p className='socialMediaParagraph' style={{marginTop:"10px"}}>Twitter — Announcements</p>
                </a>
                </Col>
                <Col style={{textAlign: "center"}}>    
                <a href="https://opensea.io/collection/masks-of-ether" target={"_blank"} rel="noreferrer" className="socialMediaLink">                
                  <Image className="socialMediaImage" src={opensea} roundedCircle/>
                  <p className='socialMediaParagraph' style={{marginTop:"10px"}}>OpenSea — Collection</p>
                </a>
                </Col>
              </Row>

            </Col>
        </Row>
        </Container>

        <Element id='faq' name='faq'>
        <Container>
          <Row>
            <Col className='mainCol'>
              <h2 className='mainTitle'>FAQ</h2>
              <div style={{backgroundColor: "#4E4E4E", marginTop: "20px", padding: "20px",  borderRadius: "25px"}}>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Release Date </span>— October 5th at 12PM PST, 3PM EST, 9PM CEST</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Tokens Withheld from sale </span>— 100 (team, marketing, airdrops)</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Total Amount of Tokens </span>— 10000</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Token Price </span>— 0.08 ETH</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Number of Traits </span>— 10</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Number of Attributes </span>— 146</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Blockchain </span>— Ethereum</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Token Standard </span>— ERC-721</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>File and Metadata Hosting </span>— IPFS</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>NFT Reveal  </span>— Instant</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Causes Supported </span>— CryptoSavannah ($50,000), Survival International ($50,000) and Community Fund (20+ ETH)</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Licensing </span>— Commercial Rights</p></Col>
            </Row>
            <Row>
              <Col className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>OpenSea Collection </span>— <a style={{textDecoration: "none", wordBreak: "break-word"}} href="https://opensea.io/collection/masks-of-ether" target={"_blank"} rel="noreferrer">https://opensea.io/collection/masks-of-ether</a></p></Col>
            </Row>
            <Row>
              <Col style={{overflow: "hidden"}} className="leftTraitsCol"><p className='mainParagraph'><span style={{fontWeight: 700}}>Verified Smart Contract </span>— <a style={{textDecoration: "none", wordBreak: "break-word"}} href="https://etherscan.io/address/0x1127c988fb011f010b082af6b0827aaeeed7d517#code" target={"_blank"} rel="noreferrer">https://etherscan.io/address/0x1127c988fb011f010b082af6b0827aaeeed7d517#code</a></p></Col>
            </Row>
            
            </div>

            </Col>
          </Row>
        </Container>
        </Element>

        <Element id='provenance' name='provenance'>
        <Container>
          <Row>
              <Col className='mainCol'>
              <h2 className='mainTitle'>Provenance</h2>
              <p className='provenanceParagraph'>This section presents the provenance record of Masks of Ether. Each NFT image is first hashed using the SHA-256 algorithm. In the order listed below, we combine all these hashes into a single string. The final proof is obtained using SHA-256 to hash this combined string. This is the final provenance record stored on the smart contract as MASKS_PROVENANCE.</p>
              <p className='provenanceParagraph'>Each NFT tokenID is assigned to an image from the initial sequence with this formula:  </p>
              <Row> 
                <Col style={{textAlign: "center"}}>
                  <p className='provenanceParagraph' style={{fontWeight: "700"}}>(tokenID + (10000 - startingIndex)) % 10000 → Initial Sequence ID</p>
                  <p className='provenanceParagraph'><span style={{fontWeight: "700"}}>Finalized Starting Index:</span> 9782</p>
                  <p className='provenanceParagraph' style={{textDecoration: "none", wordBreak: "break-word"}}><span style={{fontWeight: "700"}}>Final Proof Hash:</span> {finalHash}</p>
                </Col>
              </Row>
              <p className='provenanceParagraph' style={{fontWeight: "700"}}>Concatenated Hash String: </p>
              <p className='provenanceParagraph'><a href={concatenated_hash} download="Concatenated Hash.txt">Download the Concatenated Hash</a></p>
              <p className='provenanceParagraph' style={{fontWeight: "700"}}>Provenance Record: </p>
              <p className='provenanceParagraph'><a href={provenance_record} download="Provenance Record.txt">Download the Provenance Record</a></p>
              </Col>
          </Row>
        </Container>
        </Element>
        
        <Container style={{marginBottom:"20px"}}>
          <Row>
              <Col className='mainCol'>
                <h2 className='mainTitle'>The Team</h2>
                <p className='mainCreatorParagraph'>Masks of Ether is created by <span style={{color: "#FFE400"}}>Harrison Foko.</span> He is on a mission to create a more diverse and inclusive technology sector. His vision with Masks of Ether is to share rich and beautiful tribal African culture in the Blockchain and NFT spaces and inspire other creators to do so too. The talented <span style={{color: "#FFE400"}}>Felicity Foko</span> handles marketing and PR.</p>
                <p className='mainCreatorParagraph'>Harrison's Twitter handle is <a style={{textDecoration: "none"}} target={"_blank"} rel="noreferrer" href="https://twitter.com/harrisonfoko">@harrisonfoko</a>. Felicity can be found on Instagram <a style={{textDecoration: "none"}} target={"_blank"} rel="noreferrer" href="https://www.instagram.com/felicityfoko/">@felicityfoko</a>.</p>
                <Row> 
                  <Col style={{textAlign: "center"}}>     
                    <a target={"_blank"} rel="noreferrer" href="https://twitter.com/harrisonfoko" className="socialMediaLink">
                      <Image className="creatorImage" src={maskHarry} roundedCircle/>
                      <p style={{marginTop: "10px"}}><span className='mainCreator'>Harrison</span> (@harrisonfoko)</p>
                    </a>
                  </Col>
                  <Col style={{textAlign: "center"}}>     
                    <a target={"_blank"} rel="noreferrer" href="https://www.instagram.com/felicityfoko/" className="socialMediaLink">
                      <Image className="creatorImage" src={maskFelicity} roundedCircle/>
                      <p style={{marginTop: "10px"}}><span className='mainCreator'>Felicity</span> (@felicityfoko)</p>
                    </a>
                  </Col>
                </Row>
                  

              </Col>
          </Row>
        </Container>
          
        <hr style={{backgroundColor: "#ffffff"}}></hr>
        <footer>
          <Container>
            <Row>
              <Col style={{textAlign:"start"}} >
                <Image src={logo} className='footerImage' roundedCircle/>
              </Col>

              <Col  xs={6}>
                <a href="https://discord.gg/Td2wbJ4Meu" rel="noreferrer" target={"_blank"}>            
                  <Image src={discord} className='footerImage' roundedCircle/>
                </a> 
                <a href="https://twitter.com/MasksOfEther" rel="noreferrer" target={"_blank"}>            
                  <Image src={twitter} className='footerImage' roundedCircle/>
                </a> 
                <a href="https://opensea.io/collection/masks-of-ether" rel="noreferrer" target={"_blank"}>            
                  <Image src={opensea} className='footerImage' roundedCircle/>
                </a> 
              </Col>

              <Col className="rightTraitsCol">
                <Row> <p className="copyrightText">Copyright © Masks of Ether</p></Row>
                <Row> 
                  <a href="https://nftcalendar.io/event/masks-of-ether/" style={{textDecoration: "none"}}rel="noreferrer" target={"_blank"}>    
                    <span className="copyrightText">As seen on: </span> 
                    <Image src={nftCalendar} className='footerImage' roundedCircle/>
                  </a> 
                </Row>

              </Col>
            </Row>
          </Container>
          
        </footer>   

  

      </div>
    );
    }
}

export default App;
